import { ConfigProvider, Spin } from 'antd';
import { AppRoutes } from './AppRoutes';
import { Suspense } from 'react';
import { storeLogin } from './stores/storeLogin';
import { LoadingOutlined } from '@ant-design/icons';
export default function App() {
  return (
    <ConfigProvider>
      <div style={{ minHeight: '100vh', minWidth: '100vw' }}>
        <Suspense
          fallback={
            <div
              style={{
                minWidth: '100vw',
                minHeight: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
              />
            </div>
          }
        >
          <AppRoutes />
        </Suspense>
      </div>
    </ConfigProvider>
  );
}
