import {
  CustomUser,
  LoginPayload,
  LoginResponse,
  RegisterPayload,
} from '../interfaces/users.interface';
import HttpHelper from '../stores/httpHelpers';

export async function registerUser(
  payload: RegisterPayload
): Promise<RegisterPayload | undefined> {
  try {
    const httpHelper = HttpHelper.getInstance();
    const response = await httpHelper.post<RegisterPayload>(
      `${httpHelper.BASE_URL}/users/register/`, // Endpoint de registro
      payload
    );
    return response;
  } catch (err) {
    console.error('Erro ao registrar usuário:', err);
  }
}

export async function loginUser(
  payload: LoginPayload
): Promise<LoginResponse | undefined> {
  try {
    const httpHelper = HttpHelper.getInstance();
    const response = await httpHelper.post<LoginResponse>(
      `${httpHelper.BASE_URL}/users/login/`,
      payload
    );
    return response;
  } catch (err) {
    console.error('Erro ao realizar login:', err);
  }
}

export async function listUser(): Promise<CustomUser[] | undefined> {
  try {
    const httpHelper = HttpHelper.getInstance();
    const response = await httpHelper.get<CustomUser[]>(
      `${httpHelper.BASE_URL}/users/user/`
    );
    return response;
  } catch (err) {
    console.error('Erro ao realizar login:', err);
  }
}

export async function putUser(
  id: number,
  payload: Partial<CustomUser>
): Promise<CustomUser | undefined> {
  try {
    const httpHelper = HttpHelper.getInstance();
    const response = await httpHelper.put<CustomUser>(
      `${httpHelper.BASE_URL}/users/user/${id}/`,
      payload
    );
    return response;
  } catch (err) {
    console.error('Erro ao realizar login:', err);
  }
}

export async function deleteUser(id: number): Promise<CustomUser | undefined> {
  try {
    const httpHelper = HttpHelper.getInstance();
    const response = await httpHelper.delete<CustomUser>(
      `${httpHelper.BASE_URL}/users/user/${id}/`
    );
    return response;
  } catch (err) {
    console.error('Erro ao realizar login:', err);
  }
}

export async function getUserByUuid(
  uuid: string
): Promise<CustomUser | undefined> {
  try {
    const httpHelper = HttpHelper.getInstance();
    const response = await httpHelper.get<CustomUser>(
      `${httpHelper.BASE_URL}/users/user/${uuid}/`
    );
    return response;
  } catch (err) {
    console.error('Erro ao realizar login:', err);
  }
}

export async function getUserByJWT(): Promise<CustomUser | undefined> {
  try {
    const httpHelper = HttpHelper.getInstance();
    const response = await httpHelper.get<CustomUser>(
      `${httpHelper.BASE_URL}/users/user-info/`
    );
    return response;
  } catch (err) {
    console.error('Erro ao realizar login:', err);
  }
}
