import HttpHelper from './httpHelpers';

import {
  CustomUser,
  LoginPayload,
  LoginResponse,
} from '../interfaces/users.interface';
import { getUserByJWT, loginUser } from '../services/userService';
import { makeAutoObservable } from 'mobx';

class StoreLogin {
  email = '';
  senha = '';
  httpHelper = HttpHelper.getInstance();
  userInfo: Partial<CustomUser> = {};
  isAuthenticated = false;

  constructor() {
    makeAutoObservable(this); // Habilita reatividade automática
  }

  private setTokensToLocalStorage(access: string, refresh: string) {
    localStorage.setItem('access', access);
    localStorage.setItem('refresh', refresh);
    this.isAuthenticated = true;
  }

  private clearTokensFromLocalStorage() {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    this.isAuthenticated = false;
  }

  setTokens(valor: LoginResponse) {
    this.setTokensToLocalStorage(valor.access, valor.refresh);
  }

  async enviarFormLogin(credenciais: LoginPayload) {
    try {
      const response = await loginUser(credenciais);

      if (!response) {
        this.clearTokensFromLocalStorage();
        throw new Error('Erro ao enviar dados. Nenhum token obtido');
      }

      this.setTokens(response);
      await this.setUserInfoAndCheck();
      return response;
    } catch (error) {
      console.error('Erro ao fazer login:', error);
    }
  }

  async checkoutUser() {
    this.clearTokensFromLocalStorage();
    window.location.href = '/';
  }

  async setUserInfoAndCheck(): Promise<CustomUser | undefined> {
    try {
      const userInfo = await getUserByJWT();
      if (!userInfo) {
        this.clearTokensFromLocalStorage();
        return;
      }

      this.userInfo = userInfo;
      return userInfo;
    } catch (err) {
      console.error('Erro ao buscar informações do usuário:', err);
    }
  }
}

export const storeLogin = new StoreLogin();
